import React from "react";
import { Badge } from "react-bootstrap";

const CommonStatus = (status) => {
    if(status === "OPEN") {
        return (<Badge bg="danger">SO Approval Pending</Badge>);
    } else if(status === "SO_HALF_APPROVAL") {
        return (<Badge bg="success">SO Partially Approved</Badge>)
    } else if(status === "SO_APPROVED") {
        return (<Badge bg="success">SO Approved</Badge>)
    } else if(status === "WH_APPROVED") {
        return (<Badge bg="danger">Accounts Pending</Badge>)
    } else if(status === "AC_APPROVED") {
        return (<Badge bg="success">Accounts Approved</Badge>)
    } else if(status === "ST_APPROVED") {
        return (<Badge bg="danger">Pending for Dispatch</Badge>)
    } else if(status === "HALF_DISPATCH") {
        return (<Badge bg="danger">Pending for Full Dispatch</Badge>)
    } else if(status === "DISPATCHED") {
        return (<Badge bg="danger">In-transit</Badge>)
    } else if(status === "DELIVERED") {
        return (<Badge bg="success">Inward completed</Badge>)
    } else if(status === "REJECTED") {
        return (<Badge bg="danger">Rejected</Badge>)
    } else if(status === "CANCELLED") {
        return (<Badge bg="danger">Cancelled</Badge>)
    } else if(status === "RETURN" || status === "RETURNED") {
        return (<Badge bg="danger">Order Returned</Badge>)
    } else if(status === "REPLACE") {
        return (<Badge bg="danger">Order Replaced</Badge>)
    } else if(status === "REPLACE_REQUEST") {
        return (<Badge bg="danger">Order Replace Request</Badge>)
    } else if(status === "WH_PENDING") {
        return (<Badge bg="success">WH Approval Pending</Badge>)
    };
}
 
export default CommonStatus;